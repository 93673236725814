<template>
	<section class="content redesign">
		<div class="overlay-wrapper">
			<div class="card card-primary">
				<div class="overlay" v-if="loadingContent"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
				<div class="card-body">
					<div class="row">
						<div class="col-12 col-sm-auto form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text pr-0 rounded" style="background-color: #fff;"><i class="fi fi-rr-search"></i></div>
								</div>
								<input type="text" class="form-control border-left-0" name="q" placeholder="Cari Produk" alt="🔎︎" />
							</div>
						</div>
						<div class="col col-sm">
							<button type="button" class="btn btn-primary font-weight-bold px-4" v-on:click="updateFilter" :disabled="submitting" style="min-height: 47.6px;">
								Cari
								<span v-if="submitting" class="spinner-border spinner-border-sm ml-2" role="status"
									aria-hidden="true"></span>
							</button>
						</div>
						<div class="col-auto col-sm-auto form-group">
							<button type="button" class="btn btn-primary font-weight-bold px-4" v-on:click="addItem" :disabled="submitting" style="min-height: 47.6px;">
								<i class="fi fi-rr-plus mr-2"></i> Tambah Katalog
								<span v-if="submitting" class="spinner-border spinner-border-sm ml-2" role="status"
									aria-hidden="true"></span>
							</button>
						</div>
					</div>

					<div class="my-2">
						<table class="table table-hover" ref="tableuser" width="100%">
							<thead>
								<tr>
									<th>ID</th>
									<th>No</th>
									<th>Produk</th>
									<th>Harga</th>
									<th>Berat</th>
									<th>Ukuran (PxLxT)</th>
									<th>Kategori</th>
									<th>Aksi</th>
								</tr>
							</thead>
							<tbody @click="handleClick">
							</tbody>
						</table>
					</div>
					<div v-if="recordsTotal < 1" class="row">
						<div class="col-12 col-sm-8 col-md-5 col-lg-auto m-auto">
							<img src="../assets/img/Empty-state.svg" class="d-flex m-auto" />
							<div class="h2 text-center font-weight-bold">Katalog Produk Kosong</div>
							<div class="h5 text-center">Kamu belum pernah order sama sekali.</div>
							<div class="h5 text-center">Silahkan buat pesanan untuk mengirim paket</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="saveItem" v-on:keydown.enter.prevent="false">
						<div class="modal-header">
							<h4 class="modal-title">Detail Produk</h4>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-md-6 form-group">
									<label class="font-weight-normal">Nama Produk <span class="text-primary">(wajib diisi)</span></label>
									<input class="form-control" type="text" minlength="3" required="required" placeholder="Masukkan Nama Produk" id="name" />
								</div>
								<div class="col-md-6 form-group">
									<label class="font-weight-normal">Kategori <span class="text-primary">(wajib diisi)</span></label>
									<select class="form-control" required="required" id="category">
										<option value="" selected disabled>Pilih kategori</option>
										<option v-for="category in categoryOptions" v-bind:key="category.id" v-bind:value="category.id">
											{{ category.name }}
										</option>
									</select>
								</div>
								<div class="col-md-6 form-group">
									<label class="font-weight-normal">Harga Produk <span class="text-primary">(wajib diisi)</span></label>
									<currency-input class="form-control" currency="IDR" :precision="0" required="required" v-bind:value="0" id="price"/>
								</div>
								<div class="col-md-6 form-group">
									<label class="font-weight-normal">Berat <span class="text-primary">(wajib diisi)</span></label>
									<input class="form-control" type="number" min="0.1" step="0.1" required="required" placeholder="Bisa desimal" id="weight" />
								</div>
								<div class="col-md-4 form-group">
									<label class="font-weight-normal">Panjang</label>
									<input class="form-control" type="number" min="0" step="0.1" placeholder="Bisa desimal" id="length" />
								</div>
								<div class="col-md-4 form-group">
									<label class="font-weight-normal">Lebar</label>
									<input class="form-control" type="number" min="0" step="0.1" placeholder="Bisa desimal" id="width" />
								</div>
								<div class="col-md-4 form-group">
									<label class="font-weight-normal">Tinggi</label>
									<input class="form-control" type="number" min="0" step="0.1" placeholder="Bisa desimal" id="height" />
								</div>
								<div class="col-12 mt-3"></div>
								<div class="col-12 d-flex justify-content-end">
									<button type="submit" id="append" class="btn btn-primary font-weight-bold px-4" :disabled="submitting" style="min-height: 47.6px;">
										<i class="fi fi-rr-plus mr-2"></i> Simpan Katalog Produk
										<span v-if="submitting" class="spinner-border spinner-border-sm ml-2" role="status"
											aria-hidden="true"></span>
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="formDialogEdit" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="saveEdit" v-on:keydown.enter.prevent="false">
						<div class="modal-header">
							<h4 class="modal-title">Detail Produk</h4>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-md-6 form-group">
									<label class="control-label">Nama Produk</label>
									<input class="form-control" type="text" minlength="3" required="required" placeholder="Masukkan Nama Produk" id="name_edit" />
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Kategori</label>
									<select class="form-control" required="required" id="category_edit">
										<option value="" selected disabled></option>
										<option v-for="category in categoryOptions" v-bind:key="category.id" v-bind:value="category.id">
											{{ category.name }}
										</option>
									</select>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Harga Produk</label>
									<currency-input class="form-control" currency="IDR" :precision="0" required="required" v-bind:value="0" id="price_edit"/>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Berat</label>
									<input class="form-control" type="number" min="0.1" step="0.1" required="required" placeholder="Bisa desimal" id="weight_edit" />
								</div>
								<div class="col-md-4 form-group">
									<label class="control-label">Panjang</label>
									<input class="form-control" type="number" min="0" step="0.1" placeholder="Bisa desimal" id="length_edit" />
								</div>
								<div class="col-md-4 form-group">
									<label class="control-label">Lebar</label>
									<input class="form-control" type="number" min="0" step="0.1" placeholder="Bisa desimal" id="width_edit" />
								</div>
								<div class="col-md-4 form-group">
									<label class="control-label">Tinggi</label>
									<input class="form-control" type="number" min="0" step="0.1" placeholder="Bisa desimal" id="height_edit" />
								</div>
								<div class="col-12 mt-3"></div>
								<div class="col-12 d-flex justify-content-end">
									<button type="submit" id="append" class="btn btn-primary font-weight-bold px-4" :disabled="submitting" style="min-height: 47.6px;">
										<i class="fi fi-rr-plus mr-2"></i> Simpan Katalog Produk
										<span v-if="submitting" class="spinner-border spinner-border-sm ml-2" role="status"
											aria-hidden="true"></span>
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { createTable, authFetch, auth, openChat } from "@/libs/hxcore";
import Swal from "sweetalert2";
import "daterangepicker/daterangepicker.css";
import { CurrencyInput } from "vue-currency-input";
import moment from "moment";
import $ from "jquery";
import "icheck-material";
export default {
	name: "Katalog",
	data() {
		return {
			roles: "",
			loadingContent: false,
			filter: {},
			search: "",
			id_katalog: null,
			recordsTotal: 0,
			categoryOptions: [],
			submitting: false,
		};
	},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();

		authFetch("/catalog/category")
		.then((res) => {
			if (res.status === 201) {} else if (res.status === 400) {}
			return res.json();
		})
		.then((js) => {
			this.categoryOptions = js.data;
		});
	},
	methods: {
		updateFilter: function (e) {
			this.table.api().ajax.reload(this.foo);
		},
		addItem: function() {
			const e = this.$refs;
			$(e.formDialog).modal("show");
		},
		saveItem: function(ev) {
			this.submitting = true;
			var price_string= $("#price").val();
			var price_replace_idr = price_string.replace('IDR ','');
			var price = price_replace_idr.replaceAll(',','');

			var data = {
				"name": $("#name").val(),
				"category_id": $("#category").val(),
				"weight": parseFloat($("#weight").val()),
				"price": parseFloat(price),
				"width": Math.ceil($("#width").val()),
				"height": Math.ceil($("#height").val()),
				"length": Math.ceil($("#length").val()),
			};

			authFetch("/catalog", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.submitting = false;
				if (js.success){
					$(this.$refs.formDialog).modal("hide");
					this.table.api().ajax.reload(this.foo);
					$("#price").val(0);
					$("#name").val('');
					$("#category").val('');
					$("#weight").val('');
					$("#width").val('');
					$("#height").val('');
					$("#length").val('');
					Swal.fire({
						title: "Tambah Katalog Produk Berhasil",
						html: "Anda berhasil menambahkan <strong>"+$('#name').val()+"</strong></br>ke katalog produk.",
						icon: "success",
						width: '600px',
						timer: 3000,
						showConfirmButton: false,
						showCloseButton: true,
					});
				} else {
					Swal.fire({
						title: 'Tambah Katalog Produk Gagal',
						html: "Anda gagal menambahkan <strong>"+$('#name').val()+"</strong></br>ke katalog produk.",
						icon: 'error',
						width: '600px',
						timer: 3000,
						showConfirmButton: false,
						showCloseButton: true,
					});
				}

			})
		},
		deletedItem: function(id, name) {
			this.id_katalog = parseInt(id);
			const e = this.$refs;
			Swal.fire({
				title: 'Hapus Katalog Produk',
				html: "Anda anda yakin menghapus <strong>"+name+"</strong></br>dari katalog produk ?",
				icon: 'warning',
				width: '600px',
				showCancelButton: true,
				confirmButtonColor: '#e60000',
				confirmButtonText:'Hapus',
				cancelButtonText: 'Batalkan',
			}).then((result) => {
				if (result.isConfirmed) {
					this.submitting = true;
					var data = {
						"id_catalog": this.id_katalog,
					};

					authFetch("/catalog/deleted", {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(data),
					})
					.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
					})
					.then((js) => {
						this.submitting = false;
						this.table.api().ajax.reload(this.foo);
					})
				}
			})
		},
		editItem: function(data) {
			this.submitting = true;
			const e = this.$refs;
			var id_katalog = {
				"id_catalog": data,
			};

			authFetch("/catalog/data_by_id", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(id_katalog),
			})
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.submitting = false;
				if (js.success){
					$("#name_edit").val(js.data.name);
					$("#category_edit").val(js.data.category_id);
					$("#weight_edit").val(js.data.weight);
					$("#width_edit").val(js.data.width);
					$("#height_edit").val(js.data.height);
					$("#length_edit").val(js.data.length);
					$("#price_edit").val(js.data.price);
					$(e.formDialogEdit).modal("show");
				}
			});
		},
		saveEdit: function(data) {
			this.submitting = true;
			var price_string= $("#price_edit").val();
			var price_replace_idr = price_string.replace('IDR ','');
			var price = price_replace_idr.replaceAll(',','');

			var data = {
				"id_catalog": this.id_katalog,
				"name": $("#name_edit").val(),
				"category_id": $("#category_edit").val(),
				"weight": parseFloat($("#weight_edit").val()),
				"price": parseFloat(price),
				"width": Math.ceil($("#width_edit").val()),
				"height": Math.ceil($("#height_edit").val()),
				"length": Math.ceil($("#length_edit").val()),
			};
			
			authFetch("/catalog", {
				method: "PUT",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
			.then((res) => {
				if (res.status === 201) {} else if (res.status === 400) {}
				return res.json();
			})
			.then((js) => {
				this.submitting = false;
				if (js.success){
					$(this.$refs.formDialogEdit).modal("hide");
					this.table.api().ajax.reload(this.foo);
					Swal.fire({
						title: "Update Katalog Produk Berhasil",
						html: "Anda berhasil mengupdate <strong>"+$('#name_edit').val()+"</strong></br>ke katalog produk.",
						icon: 'success',
						width: '600px',
						timer: 3000,
						showConfirmButton: false,
						showCloseButton: true,
					});
				} else {
					Swal.fire({
						title: 'Update Katalog Produk Gagal',
						html: "Anda gagal mengupdate <strong>"+$('#name').val()+"</strong></br>ke katalog produk.",
						icon: 'error',
						width: '600px',
						timer: 3000,
						showConfirmButton: false,
						showCloseButton: true,
					});
				}
			})
		},
		handleClick(e) {
			if (e.target.matches(".deleted")) {
				this.deletedItem(e.target.id, e.target.getAttribute("data-value"));
			}
			if (e.target.matches(".edit")) {
				this.id_katalog = parseInt(e.target.id);
				this.editItem(e.target.id);
			}
		},
		foo: function(json) {
			this.recordsTotal = json.recordsTotal;
		}
	},
	components: {
		CurrencyInput,
	},
	mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Katalog Produk'
		});
		const e = this.$refs;
		const self = this;
		var isShipper = this.userInfo.role_id > 10;
		var filterBy = [1, 2, 3, 4, 5, 6];
		if(isShipper)filterBy = [1, 2, 3, 4, 5, 6];
		this.table = createTable(e.tableuser, {
			title: "Katalog Produk",
			responsive: true,
			scrollX: true,
			roles: this.$route.params.roles,
			ajax: "/catalog",
			frame: false,
			toolbarEl: e.toolbar,
			lengthChange: false,
			filter: false,
			stateSave: true,
			// dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
			// 	"<'row'<'col-sm-12'tr>>" +
			// 	"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
			processing: true,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
			},
			filterBy: filterBy,
			paramData: function (value) {
				value["search"]["value"]= $("input[name=q]").val();
			},
			columns: [
				{
					data: "id",
					visible: false,
				},
				{
					data: "no",
					sortable: false,
					render: function (data, type, row, meta) {
						return data;
					},
				},
				{
					data: "name",
					render: function (data, type, row, meta) {
						return data;
					},
				},
				{
					data: "price",
					render: function (data, type, row, meta) {
						var number_string = data.replace(/[^,\d]/g, '').toString(),
						split   		= number_string.split(','),
						sisa	 		= split[0].length % 3,
						rupiah	 		= split[0].substr(0, sisa),
						ribuan	 		= split[0].substr(sisa).match(/\d{3}/gi);

						if(ribuan){
							var separator = sisa ? '.' : '';
							rupiah += separator + ribuan.join('.');
						}

						rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;

						return (`<span>Rp ${rupiah}</span>`);
					},
				},
				{
					data: "weight",
					render: function (data, type, row, meta) {
						return (`<span>${data} Kg</span>`);
					},
				},
				{
					sortable: false,
					render: function (data, type, row, meta) {
						return (`<span>${row.length}cm x ${row.width}cm x ${row.height}cm</span>`);
					},
				},
				{
					data: "category_name",
					render: function (data, type, row, meta) {
						// const date = new Date(data);
						// var day = date.getDate();
						// var month = date.toLocaleString('default', { month: 'short' });
						// var years = date.getFullYear();
						// var hours = date.getHours();
						// var minutes = date.getMinutes();
						// var formattedNumber = ("0" + day).slice(-2);

						// return (`<span>${formattedNumber} ${month} ${years}, ${hours}:${minutes}</span>`);
						return (data);
					},
				},
				{
					sortable: false,
					render: function (data, type, row, meta) {
						return ('<div class="row">'+
									'<span class="col-auto col-md"><button id="'+row.id+'" class="btn btn-block btn-primary edit" style="background-color: #1E4F99; border-color: #1E4F99; font-size: 8pt;"><i id="'+row.id+'" class="fa fa-edit edit"></i><span class="d-inline d-sm-inline ml-1 edit" id="'+row.id+'">Lihat&nbsp;detail</button></span>'+
									'<span class="col-auto col-md"><button id="'+row.id+'" data-value="'+row.name+'" class="btn btn-block btn-outline-danger deleted" style="font-size: 8pt;"><i class="fa fa-trash deleted" id="'+row.id+'" data-value="'+row.name+'"></i><span id="'+row.id+'" data-value="'+row.name+'" class="d-inline d-sm-inline ml-1 deleted">Hapus</button></span>'+
								'</div>');
					},
				},
			],
			initComplete: function (settings, json) {
				$('.loading-overlay').removeClass('show');
			},
		});
		this.table.api().ajax.reload(this.foo);
	},
};
</script>
<style scoped>
.dataTables_length{padding-top: 0.5em;}
</style>